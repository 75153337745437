.spinner {
  border: 4px solid #ffffff; /* light grey */
  border-top: 4px solid #3498db; /* blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.small_spinner {
  border: 2px solid #ffffff; /* light grey */
  border-top: 2px solid #3498db; /* blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
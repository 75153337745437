@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900');
@import 'spinners';

$fa-font-path: '~font-awesome/fonts' !default;
@import '~font-awesome/scss/font-awesome';

$break-tiny: 300px;
$break-small: 767px;
$break-medium: 992px;

body {
  font-family: 'Source Sans Pro', sans-serif;
  .lity-iframe .lity-iframe-container {
    height: 100%;
    border-radius: 0px;
    padding-top: 0;
  }
  .lity-opened {
    background-color: #f8f9fa;
  }
  .lity-iframe .lity-container {
    width: 100%;
    height: 100%;
    max-width: none;
    .lity-content {
      height: 100%;
    }
  }
}

html.lity-active {
  body {
    position: fixed;
    overflow: hidden;
  }
}

button.lity-close {
  right: 5px;
  top: 5px;
  width: 45px;
  height: 45px;
  font-size: 30px !important;
  position: absolute;
  color: #758498 !important;
  border-radius: 5px;
  font-weight: 600;
  text-shadow: none;
  background-color: #FFFFFF;
  border: 1px solid #E3E8F0;
  text-shadow: none !important;
  &:hover {
    opacity: .5;
    background-color: #FFFFFF;
    border: 1px solid #E3E8F0;
    color: #758498 !important;
  }
}

button.btn-delete {
  padding: .25em .5em;
  background-color: transparent;
  color: #DD352E;
  border: 1px solid #E6E8ED;
  border-radius: 4px;
  font-size: 1em;
  &:hover {
    background-color: #F5D3D1;
    border: 1px solid #F5D3D1;
  }
  &.disabled {
    opacity: .5;
    border: 1px solid #fff;
    &:hover {
      background-color: transparent;
    }
  }
}

.custom-select {
  &__control {
    background-color: #fff !important;
    color: #3c4257;
    border-color: #e3e8ef !important;
    font-size: .9em;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    transition: .25s !important;
    &:hover {
      cursor: pointer;
      box-shadow: none !important;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    z-index: 99 !important;
    font-size: .9em !important;
  }
  &__option--is-selected {
    display: none !important;
  }
  &--is-disabled {
    .custom-select__control {
      box-shadow: none;
      opacity: .5;
    }
  }
}

label.secondary {
  padding: 5px 14px;
  font-size: 15px;
  line-height: 21px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 3px;
  background-color: #fff;
  margin-bottom: 0;
  &:hover {
    background-color: #F6F6F6;
  }
  &.margin-bottom {
    margin-bottom: 40px;
  }
}

.btn-white {
  box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent;
  background-color: #fff;
  margin-right: .25em;
  color: #4f566b;
  padding: .35em .75em;
  border-radius: 3px;
  border: 1px solid #E3E8EF;
  font-weight: 600;
  min-height: 32.6px;
  font-size: .9em;
  text-decoration: none !important;
  transition: .25s;
  outline: none !important;
  cursor: pointer;
  &:hover {
    background-color: #F1F1F1;
    box-shadow: none;
  }
  &:disabled {
    opacity: .5;
    box-shadow: none;
    background-color: #fff !important;
  }
}

.btn-blue {
  box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent;
  background-color: #4760ff;
  margin-right: .25em;
  color: #fff;
  padding: .35em .75em;
  border-radius: 3px;
  border: none;
  font-weight: 600;
  min-height: 32.6px;
  font-size: 15px;
  text-decoration: none !important;
  transition: .25s;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: .5;
    box-shadow: none;
  }
  &:hover {
    background-color: #3B52E6;
    box-shadow: none;
  }
}

label.secondary.disabled, label.secondary[disabled] {
  opacity: .5;
  &:hover {
    background-color: #FFF;
  }
}

.h1-style {
  font-size: 3.052em;
  padding: 0;
  margin: 0;
  letter-spacing: -.015em;
  font-weight: 700;
  line-height: 1.1em;
  font-kerning: normal;
  font-feature-settings: "kern" 1;
  @media screen and (max-width: $break-small) {
    font-size: 2.9em;
    line-height: 1.2em;
  }
}

.h1-style-small {
  font-size: 2.441em;
  padding: 0;
  margin: 0;
  letter-spacing: -.015em;
  font-weight: 800;
  line-height: 1.1em;
  @media screen and (max-width: $break-small) {
    font-size: 2.2em;
    line-height: 1.2em;
  }
}

.h2-style {
  font-size: 1.953em;
  font-weight: 400;
  padding: 0;
  margin: 0;
  letter-spacing: -.01em;
  line-height: 1.3em;
  @media screen and (max-width: $break-small) {

  }
}

.h3-style {
  font-size: 1.563em;
  font-weight: 700;
  line-height: 1.3em;
  padding: 0;
  margin: 0;
}

.h4-style {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
}

.h5-style {
  font-size: 1em;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: .06em;
  font-weight: 600;
}

.text-input-style {
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(27, 31, 35, .075);
  border-radius: 5px;
  padding: 6px 8px;
  border: 1px solid #ced4da;
}

.text-btn-style {
  padding: 0;
  border: none;
  font-size: 1em;
  margin: 0;
  color: #0073E2;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: #15232b;
  }
}

.popover-overlay-message {
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  background-image: linear-gradient(to bottom,rgba(49,49,47,.99), #262625);
  max-width: 500px;
  z-index: 44;
}

.react-tiny-popover-container {
  z-index: 22;
}

.circular-text-popover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  background-color: #EFF1F6;
  width: 20px;
  padding: 0 0 0 1px;
  height: 20px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 14px;
  color: #62697B;
}

.portal-loading {
  padding: 5em 19px;
  width: 100%;
  height: 100vh;
  background-color: #f6f8fb;
  text-align: center;
  font-size: 1.333em;
  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0 0 1em;
    border: none;
  }
  &__title {
    font-size: 1em;
    font-weight: 600;
    color: #3C465F;
    padding: 0;
    margin: 0;
  }
}

.portal-message {
  padding: 5em 0;
  text-align: center;
  &__wrapper {
    max-width: 620px;
    margin: 0 auto;
    padding: 0 19px;
  }
  &__message {
    white-space: wrap;
    padding: 2em;
    background-color: #fff3cd;
    font-size: 1.333em;
    font-weight: 400;
    line-height: 1.3em;
    color: #856404;
    border-radius: 12px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.btn-primary {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font-size: 1em;
  padding: .5em 1.5em;
  border-radius: 4px;
  border: none;
  background-color: #0073E2;
  box-shadow: 0px 2px 0px #05529C;
  color: #fff;
  font-weight: 500;
  text-decoration: none !important;
  &.disabled {
    pointer-events: none;
    opacity: .5;
    box-shadow: none;
  }
  &:hover {
    color: #fff;
    background-color: #0366C6;
  }
}

.btn-secondary {
  display: inline-block;
  font-size: 1em;
  padding: .6em 1.5em;
  border-radius: 4px;
  background-color: transparent;
  color: #484848;
  box-shadow: 0px 2px 0px #939393;
  border: 1px solid #979797;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
  &:hover {
    background-color: #F1F1F1;
    color: #484848;
  }
}

.primary {
  padding: 10px 20px;
  border: 1px solid #1454d4;
  background-color: #286efa;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  line-height: 14px;
  &:hover {
    background-color: #266EE1;
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
  &--bigger {
    font-size: 20px;
    padding: 15px 25px;
  }
}

.secondary {
  padding: 10px 20px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
  line-height: 14px;
  &:hover {
    background-color: #F6F6F6;
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.portal-client-invite__accept {
  &__navbar {
    border-bottom: 1px solid lightgrey;
  }
  &__logo {
    max-width: 420px;
    max-height: 190px;
  }
  &__body {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    h2 {
      margin-bottom: 5px;
    }
    h4 {
      margin-top: 0;
    }
  }
}

.alert {
  padding: 2em 0;
  margin: 0;
  border-radius: 0;
  position: relative;
  width: 100%;
  border: 0;
  .container {
    max-width: 1200px;
    padding-left: 50px;
  }
  .button_section {
    max-width: 980px;
  }
  button {
    float: right;
    background-color: transparent;
    border: 0;
  }
  h2 {
    font-size: 1em;
    padding: 0 0 .5em;
    margin: 0;
  }
  ul {
    margin: 0;
  }
}

.portal-client-landing {
  display: flex;
  flex-direction: column;
  &__sections {
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    &__section {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 4em;
      border-bottom: 1px solid #AAAAAA;
      background-color: #E4E4E4;
      border-right: 5px solid #E4E4E4;
      &.active {
        background-color: #D8D8D8;
        border-right: 5px solid #0E99E0;
      }
    }
  }
  &__interviews {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 80%;
    padding: 40px;
    &__interview {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 40%;
      border: 1px solid lightgrey;
      border-radius: 5px;
      padding: 10px;
      margin: 0 10px 10px 0;
      img {
        margin-bottom: 10px;
      }
      span {
        margin: 3px 0;
      }
      button {
        cursor: pointer;
        margin: 5px 0;
        width: 50%;
      }
    }
    &__interview-name {
      font-weight: 600;
    }
    &__interview-thumbnail {
      max-width: 420px;
      max-height: 190px;
    }
  }
}

.portal-client-settings {
  padding: 4em 0;
  margin-top: 55px;
  background-color: #f6f8fb;
  .p2-style {
    color: #778498;
    line-height: 1.2em;
  }
  .h2-style {
    font-size: 1.333em;
    font-weight: 800;
  }
  h4 {
    font-size: 1em;
    font-weight: 600;
  }
  &__nav {
    padding: 0 0 1.5em;
  }
  &__wrapper {
    max-width: 50%;
    margin: 0 auto;
  }
  &__title-row {
    padding: 0 0 1em;
    margin: 0 0 2em;
    border-bottom: 1px solid #DEDFE2;
  }
  &__columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 2em;
    border-bottom: 1px solid #DEDFE2;
    margin-bottom: 2em;
    &--1 {
      flex-basis: 30%;
    }
    &--2 {
      flex-basis: 65%;
    }
    &:nth-child(3) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  &__field {
    display: flex;
    flex-direction: column;
    padding: 1.5em 0 0;
    &--label-row {
      display: flex;
      justify-content: space-between;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  label {
    color: #1a1f36;
    font-weight: 600;
    font-size: .9em;
    padding: 0 0 .25em;
    margin: 0;
  }
  &__save-row {
    text-align: right;
  }
  @media screen and (max-width: $break-small){
    margin-top: 70px;
    padding: 1.5em 19px;
    .p2-style {
      padding: 0 0 1em;
    }
    .h2-style {
      font-size: 1.777em;
    }
    &__wrapper {
      max-width: none;
    }
    &__columns {
      flex-direction: column;
    }
  }
}

.portal-client-payment {
  background-color: #F8FAFC;
  min-height: calc(100vh - 55px);
  padding: 4em 0;
  margin-top: 55px;
  &__columns {
    display: flex;
    padding: 2em;
    border-bottom: 1px solid #ECECF0;
    &__column {
      &--right {
        margin-left: 3em;
      }
    }
    &__left {
      flex-basis: 35%;
    }
  }
  h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.5em;
  }
  .h2-style {
    padding: 0 0 1em;
    font-size: 1.333em;
    font-weight: 800;
  }
  &__wrapper {
    max-width: 520px;
    margin: 0 auto;
    padding: 0 19px;
  }
  &__payment-form {
    width: 100%;
    .card-element {
      margin-top: 10px;
    }
    &__errors {
      margin-top: 10px;
    }
    &__row {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #D4DDE4;
      margin-bottom: 10px;
      &--half-rows {
        border-bottom: none;
        margin-bottom: 0;
      }
      &--half {
        width: 50%;
        &:first-child {
          margin-right: 5px;
        }
      }
      input {
        width: 100%;
        padding: 7px 0;
        color: #32325d;
        border: none;
      }
    }
  }
  &__text-link {
    color: #4760ff;
  }
  &__subscription-details-header {
    background-color: #F6FAFD;
    padding: .5em 2em;
    border-top: 1px solid #ECECF0;
    border-bottom: 1px solid #ECECF0;
    color: #525f7f;
    text-transform: uppercase;
    letter-spacing: .035em;
    font-weight: 600;
    font-size: .9em;
  }
  &__subscription-details-row {
    padding: 2em;
    border-bottom: 1px solid #ECECF0;
    &__list {
      margin: 0;
    }
    &__update-btn {
      padding: 0;
      background-color: transparent;
      border: none;
      color: #4760ff;
      outline: none !important;
      &:hover {
        color: #1D2FA0;
      }
    }
  }
  &__card {
    border-radius: 6px;
    background-color: #fff;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
    &__label {
      color: #252c47;
      font-weight: 600;
    }
    &--header {
      padding: 2em;
      &.subscription-page {
        padding-bottom: 1.5em;
      }
    }
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 1em;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &--price {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__amount {
        margin: 0 5px 0;
        padding: 0;
        color: #1C2A3B;
        font-weight: 800;
        font-size: 1.2em;
      }
      &__frequency {
        font-weight: 400;
        font-size: 15px;
        margin: 0 0 0 5px;
        color: #485C67;
        &.complete {
          padding: 11px 20px;
          background-color: #15BB0A;
          border-color: #15BB0A;
        }
      }
    }
    &--label-spacer {
      background-color: #F6FAFD;
      padding: .5em 2em;
      border-top: 1px solid #ECECF0;
      border-bottom: 1px solid #ECECF0;
      color: #525f7f;
      text-transform: uppercase;
      letter-spacing: .035em;
      font-weight: 600;
      font-size: .9em;
    }
    input {
      width: 100%;
      padding: 7px 0;
      color: #32325d;
      border: none;
      padding: 1em 0;
    }
    &--billing {
      padding: 1em 2em;
    }
    &--input {
      border-bottom: 1px solid #D4DDE4;
    }
    &--split-input {
      display: flex;
      flex-direction: row;
      &__col-1 {
        flex-basis: 50%;
      }
      &__col-2 {
        flex-basis: 50%;
      }
    }
    &__spaced-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #D4DDE4;
      &:first-child {
        padding-top: 0;
      }
      &__price {
        &__amount {
          margin: 0 5px 0;
          padding: 0;
          color: #1C2A3B;
          font-weight: 600;
          font-size: 20px;
        }
        &__frequency {
          font-weight: 400;
          font-size: 15px;
          margin: 0 0 2px 5px;
          color: #485C67;
        }
      }
      &__title {
        font-size: 15px;
        font-weight: 500;
      }
      &__price {
        display: flex;
        align-items: center;
        &__amount {
          margin: 0 5px 0;
          font-size: 20px;
        }
        &__frequency {
          font-size: 15px;
          font-weight: 400;
          margin: 0 0 2px 5px;
          color: #485C67;
        }
      }
    }
    .card-element {
      padding: 2em;
    }
    .btn-blue {
      width: 100%;
      padding: .5em 1em;
    }
    &--button-row {
      padding: 0 2em 2em;
      .small_spinner {
        /*height: 18px;*/
        /*width: 18px;*/
      }
    }
    &--disclaimer {
      padding: 0 2em 2em;
      font-size: 1em;
      color: #6F6F6F;
    }
    &--errors {
      margin-top: -2em;
      background-color: #f8d7da;
      padding: 1em;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      font-size: 1.1em;
      line-height: 1.4em;
      color: #721c24;
      border: 1px solid #f5c6cb;
    }
  }
  .small_spinner {
    display: inline-block;
  }
  .fa-check {
    border: 1px solid white;
    border-radius: 50%;
    padding: 3px;
  }
  @media screen and (max-width: $break-small){
    padding-top: 7em;
    min-height: 100vh;
  }
}

.app-page-payment__payment {
  .payment-method {
    margin: 0 10px;
  }
}

.portal-client-subscriptions {
  .portal-client-payment__body__join__spaced-row {
    width: 30%;
  }
  .portal-client-payment__body__join__payment__submit {
    margin-top: 10px;
  }
}

.payment-alert {
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #f3d6d6;
}

/* -- Client Dashboard -- */
.portal-dashboard {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  &__body {
    flex-grow: 1;
    background-color: #F8FAFC;
    overflow-x: hidden;
    margin-left:  215px;
  }
  &__header {
    margin-top: 55px;
    margin-bottom: -55px;
    &__logo {
      img {
        width: 100%;
        height: 30%;
      }
    }
    &__header-text {
      padding: 2em 5em 0;
      flex-basis: 80%;
    }
  }
  &__sections {
    width: 215px;
    background-color: #fff;
    border-right: 1px solid #E4E9F0;
    padding: 1.5em 0;
    position: fixed;
    height: 100%;
    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      color: #8792A3;
      line-height: 1.1em;
      padding: .5em 1.25em;
      font-size: 1em;
      word-wrap: break-word;
      border-radius: 0;
      letter-spacing: .025em;
      border-right: 4px solid transparent;
      font-weight: 600;
      text-decoration: none !important;
      .material-icons {
        color: #8792A3;
        font-size: 1.1em;
        margin: 0;
        padding: 2px .5em 0 0;
        min-width: 24px;
      }
      &:hover {
        text-decoration: none;
      }
      &.active {
        font-weight: 600;
        color: #4760ff;
        border-right: 4px solid #4760ff;
        .material-icons {
          color: #4760ff;
        }
        &:hover {
          cursor: default;
          background-color: #fff !important;
          color: #4760ff;
        }
      }
      &:hover {
        cursor: pointer;
        background-color: #EBF0F4;
        color: #8792A3;
      }
    }
    &__header {
      padding: 0 1.25em 3em;
    }
  }
  &__apps {
    padding: 2em 5em 0 5em;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 55px;
  }
  &__session-count {
    font-size: .9em;
    color: #656f84;
    padding: 0 0 0 1em;
    &--label {
      padding: 0 0 0 .25em;
    }
  }
  &__card {
    max-width: 300px;
    flex-basis: 28%;
    margin: 2em 2em 2em 0;
    padding: 1.25em;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(130, 130, 130, .5);
    min-width: 270px;
    border: 1px solid #E8E8EE;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .btn-blue {
      i {
        padding: 0 0 0 .5em;
      }
    }
    &--header {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &--title {
      font-size: 1em;
      padding: 0 0 0 .75em;
    }
    &--description {
      font-size: .9em;
      color: #5c6a7f;
      line-height: 1.3em;
      max-height: 255px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--thumbnail {
      max-width: 35px;
      border-radius: 6px;
    }
    &--main {
      text-align: left;
      padding: .5em 0 0;
    }
    &--btn-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: .75em;
    }
  }
  @media screen and (max-width: $break-medium){
    &__apps {
      padding: 2em 2em 0 2em;
    }
    &__header {
      &__header-text {
        padding: 2em 2em 0;
      }
    }
  }
  @media screen and (max-width: $break-small){
    overflow: hidden;
    &__body {
      flex-direction: column;
      margin-left: 0;
    }
    &__header {
      margin-top: 108px;
      &__logo {
        img {
          height: 140px;
        }
      }
      &__header-text {
        padding: 2em 19px 0;
      }
    }
    &__sections {
      width: 100%;
      position: fixed;
      margin-top: 57px;
      padding: 1em 19px;
      height: auto;
      display: flex;
      flex-direction: row;
      z-index: 99;
      border-bottom: 1px solid #E4E9F0;
      overflow: scroll;
      &__header {
        display: none;
      }
      &--item {
        width: auto;
        padding: 0;
        margin: 0 1em 0 0;
        border-radius: 0;
        background-color: #fff;
        white-space: nowrap;
        i {
          padding: 0 5px 0 0;
        }
        &.active {
          border-right: none;
        }
        &:hover {
          background-color: #fff;
        }
        .material-icons {
          padding: 0 .5em 0 0;
          min-width: auto;
        }
      }
    }
    &__apps {
      padding: 2em 19px;
      margin-top: 108px;
      flex-grow: 1;
    }
    &__card {
      flex-basis: 100%;
      margin: 0 auto 1.5em;
      min-width: 100%;
    }
  }
}

.portal-brand {
  &__username {
    cursor: pointer;
    &--label {
      color: #959fad;
      font-size: 12.5px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .025em;
    }
    &--name {
      text-transform: capitalize;
      font-size: 1.1em;
      font-weight: 700;
      letter-spacing: -.025em;
    }
  }
  &__logo {
    max-height: 40px;
    cursor: pointer;
    max-width: 100%;
  }
}

.payment-portal-brand {
  &__username {
    cursor: pointer;
    &--label {
      color: #959fad;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: .025em;
    }
    &--name {
      text-transform: capitalize;
      font-size: 1.1em;
      font-weight: 800;
    }
  }
  &__logo {
    max-height: 40px;
    cursor: pointer;
    max-width: 100%;
  }
}

.app-sessions {
  padding: 4em 0;
  .p2-style {
    color: #778498;
  }
  .btn-delete {
    margin: 0 0 0 .75em;
    box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent;
  }
  .text-btn-style {
    padding: .25em .5em;
    border: 1px solid #E6E8ED;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent;
    color: #4f566b;
    &:hover {
      background-color: #E7E9EE;
    }
  }
  &__wrapper {
    max-width: 70%;
    margin: 0 auto;
  }
  &__nav {
    padding: 0 0 1.5em;
  }
  &__back-btn {
    color: #4760ff;
    font-weight: 600;
    i {
      padding: 0 .45em 0 0;
    }
    &:hover {
      cursor: pointer;
      color: #1D2FA0;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #DEDFE2;
    padding: 0 0 1em;
    margin: 0 0 2em;
  }
  &__table-header {
    display: flex;
    margin-top: 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    flex-direction: row;
    background-color: #E7E9EE;
    color: #62697B;
    border: 1px solid #E6E8ED;
    align-items: center;
    &--item {
      flex-basis: 33%;
      padding: .25em .75em;
      &:last-child {
        text-align: right;
      }
    }
  }
  &__table-body {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-left: 1px solid #E6E8ED;
    border-right: 1px solid #E6E8ED;
    border-bottom: 1px solid #E6E8ED;
    align-items: center;
    &--item {
      flex-basis: 33%;
      padding: .75em;
      line-height: 1.3em;
      &:last-child {
        justify-content: flex-end;
        padding: .75em .75em .75em 0;
        display: flex;
        flex-direction: row;
      }
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  @media screen and (max-width: $break-small){
    padding: 1.5em;
    margin-top: 137.89px;
    &__wrapper {
      max-width: none;
    }
    .h2-style {
      font-size: 1.333em;
    }
    &__table-body {
      &--item {
        &:last-child {

        }
      }
    }
  }
}

.all-sessions {
  margin-top: 55px;
  padding: 5em 0;
  background-color: #f6f8fb;
  min-height: calc(100vh - 72px);
  &__nav {
    padding: 0 0 .75em;
  }
  .h2-style {
    padding: 0 0 1em;
    font-size: 1.333em;
    font-weight: 800;
  }
  &__wrapper {
    max-width: 55%;
    margin: 0 auto;
  }
  &__title-row {
    padding: 0 0 1em;
    margin: 0 0 2em;
    border-bottom: 1px solid #DEDFE2;
  }
  &__app-row {
    margin: 0 0 3em;
  }
  .btn-delete {
    margin: 0 0 0 .75em;
  }
  .text-btn-style {
    padding: .25em .5em;
    border: 1px solid #E6E8ED;
    border-radius: 4px;
    &:hover {
      background-color: #E7E9EE;
    }
  }
  @media screen and (max-width: $break-small){
    margin-top: 70px;
    padding: 1.5em 19px;
    .h2-style {
      font-size: 1.777em;
    }
    &__wrapper {
      max-width: none;
    }
  }
}

.row-expand-btn {
  border-radius: 100px;
  padding: 0 .25em;
  font-size: .9em;
  color: #0073E2;
  &:hover {
    cursor: pointer;
    background-color: #CEE4F9;
  }
}

.table-refresh-notification {
  padding: 0 0 0 1em;
  cursor: pointer;
  &__text {
    border-radius: 10px;
    background-color: #d4edda;
    font-weight: 600;
    font-size: 15px;
    padding: 8px 15px;
    color: #155724;
    boder: 1px solid #c3e6cb;
  }
}

.portal-records {
  padding: 1em 5em 5em;
  &__loading {
    &--header {
      height: 24px;
      width: 250px;
      background-color: #F6F8FA;
      border-radius: 8px;
      margin: 0 0 .5em;
      border: 1px solid rgba(205, 211, 213, 0.2);
    }
    &--buttons {
      height: 24px;
      width: 100px;
      background-color: #F6F8FA;
      border-radius: 8px;
      margin: 0 0 1em;
      border: 1px solid rgba(205, 211, 213, 0.2);
    }
    &--table-wrapper {
      width: 100%;
      background-color: #F6F8FA;
      border: 1px solid rgba(205, 211, 213, 0.7);
      border-radius: 5px;
      padding: .5em;
    }
    &--table {
      border: 1px solid rgba(205, 211, 213, 0.4);
      width: 100%;
      position: relative;
    }
    &--table-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(226,230,235,.2);
      padding: 3em;
      text-align: center;
    }
    &--table-spinner-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &--table-spinner-title {
      font-size: 17px;
      font-weight: 600;
      padding-top: 1em;
      color: #3C465F;
    }
    &--table-row {
      background-color: #fff;
      height: 40px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(205, 211, 213, 0.4);
      &:first-child {
        background-color: #F5F7F8;
        height: 30px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    &--table-col-small {
      flex-basis: 5%;
      border-right: 1px solid rgba(205, 211, 213, 0.4);
    }
    &--table-col-med {
      flex-basis: 20%;
      border-right: 1px solid rgba(205, 211, 213, 0.4);
    }
  }
  &__toolbar {
    display: flex;
    align-items: center;
    background-color: #F8FAFC;
    &__actions {
      display: flex;
      padding: 0 .75em 0 0;
      z-index: 99;
      .circular-tooltip {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
      &__control {
        width: 200px;
        font-size: 15px;
        &--is-focused {
          background-color: #eff3f6 !important;
          border: 1px solid #CCD9E0 !important;
        }
        .css-1wa3eu0-placeholder {
          color: #001D37;
        }
        .css-1okebmr-indicatorSeparator {
          display: none !important;
        }
        .css-109onse-indicatorSeparator {
          display: none !important;
        }
        &.css-1fhf3k1-control {
          opacity: .5;
          background-color: #eff3f6;
          border: 1px solid #CCD9E0;
          min-height: auto !important;
        }
        &.css-1pahdxg-control {
          min-height: auto !important;
          box-shadow: none;
        }
        .css-tlfecz-indicatorContainer {
          padding: 4px 8px;
        }
        .css-1gtu0rj-indicatorContainer {
          padding: 4px 8px;
        }
        &.css-yk16xz-control {
          background-color: #eff3f6;
          background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
          border: 1px solid #CCD9E0;
          color: #001D37;
          min-height: auto !important;
          position: relative;
          z-index: 99;
          &:hover {
            background-image: none;
            background-color: #eff3f6;
            cursor: pointer;
          }
        }
      }
      .portal-records__toolbar__actions__option {
        &:hover {
          cursor: pointer;
        }
      }
      .portal-records__toolbar__actions__menu {
        width: 300px;
        z-index: 9999;
        position: absolute;
      }
    }
    .btn-delete {
      padding: 3px 8px;
      margin: .5em .75em .5em 0;
      border-radius: 4px;
      color: #DD352E;
      border:1px solid #DD352E;
      i {
        padding: 0 .5em 0 0;
      }
      &:hover {
        background-color: #F9DDDC;
      }
    }
    &__apps {
      &__app {
        margin: 0 .5em 0 0;
        padding: .4em .75em;
        background-color: #eff3f6;
        background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
        border-radius: 5px;
        color: #001D37;
        border: 1px solid #CCD9E0;
        font-size: 15px;
        &:hover {
          background-color: #EFF3F6;
          background-image: none;
          border-color: #B3B3B3;
        }
        &.disabled {
          pointer-events: none;
          opacity: .5;
        }
      }
    }
  }
  &__table-wrapper {
    padding: 1em;
  }
  &__table {
    position: relative;
    overflow-x: scroll;
    border: 1px solid rgba(205, 211, 213, 0.7);
    &__empty {
      padding: .75em;
      background-color: transparent;
      h4 {
        color: #8b8b8b;
        font-size: 1em;
        padding: 0;
        margin: 0;
      }
    }
    &__column-names {
      top: 0px;
      display: flex;
      z-index: 1;
      background-color: #F5F7F8;
      width: fit-content;
      .portal-records__table__row__expand {
        background-color: #F4F7F8;
      }
      .portal-records__table__row__permissions {
        background-color: #E7E9EE;
        border-bottom: 1px solid #E6E8ED;
        border-left: 1px solid #E6E8ED;
        border-top: 1px solid #E6E8ED;
      }
      &__divider {
        cursor: col-resize;
        position: absolute;
        left: -5.5px;
        width: 10px;
        height: 100%;
        z-index: 5;
      }
      &__item-wrapper {
        position: relative;
      }
      &__item {
        display: flex;
        align-items: center;
        padding: .2em 0 .2em .75em;
        background-color: #F5F7F8;
        color: #252C47;
        font-size: .9em;
        min-height: 30.75px;
        font-weight: 600;
        border-right: 1px solid rgba(205, 211, 213, 0.4);
        white-space: nowrap;
        /*overflow: hidden;*/
        text-overflow: ellipsis;
        position: relative;
        border-bottom: 1px solid rgba(205, 211, 213, 0.7);
        i.fa-sort {
          margin-right: .5em;
          padding: 0;
        }
        i.fa-lock {
          color: #a6b0bf;
        }
        &__dropdown-btn {
          margin-left: auto;
          &--table {
            button {
              border: none;
              line-height: 14px;
              background-color: transparent !important;
              background-image: none;
            }
          }
          &--modal {
            .dropdown-menu--column {
              width: 200px;
            }
            button {
              font-size: 16px;
            }
          }
        }
        &__dropdown-menu {
          font-size: 1em !important;
          box-shadow: 0 2px 4px rgba(0,0,0,.25) !important;
          border-radius: 3px !important;
          min-width: 125px !important;
          left: auto !important;
          right: -5px !important;
          z-index: 100 !important;
          padding: 0 !important;
          border: 1px solid #CDD3D5;
          i {
            padding: 0 8px 0 0;
          }
          &--table {
            left: 0 !important;
          }
          &--column {
            min-width: 165px !important;
          }
          .dropdown---menu-item---1LjoL {
            padding: .5em .75em !important;
            &:hover {
              background-color: #F4F4F4 !important;
            }
          }
          &--btn {
            border-top: 1px solid #CDD3D5;
            &:first-child {
              border-top: none;
            }
            &__remove-sort {
              margin-left: auto;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              .fa-times {
                padding: 0;
              }
            }
            &.current-sort {
              div.dropdown---menu-item---1LjoL {
                display: flex;
              }
            }
          }
        }
      }
      i {
        padding: 0 0 0 .5em;
      }
    }
    &__row-wrapper {
      width: fit-content;
    }
    &__row {
      display: flex;
      flex-direction: row;
      min-height: 2.4em;
      &__expand {
        min-width: 20px;
        background-color: #fff;
        text-align: right;
        border-right: none;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 1.1em;
      }
      &__expand-row-col-header-placeholder {
        min-width: 31px;
      }
      &__select {
        display: flex;
        justify-content: space-between;
        position: sticky;
        left: 0;
        align-items: center;
        padding: 5px .5em 5px .5em;
        background-color: #fff;
        z-index: 2;
        margin-right: -1px;
        border-bottom: 1px solid rgba(205, 211, 213, 0.7);
        border-right: 1px solid rgba(205, 211, 213, 0.4);
        &--expand-button-placeholder {
          min-width: 31px;
        }
        &.clickable {
          cursor: pointer;
        }
        &.clickable-active {
          background-color: #E7F6FB;
        }
        &--input {

        }
        &--all {
          background-color: #F5F7F8;
          border-bottom: 1px solid rgba(205, 211, 213, 0.7);
          border-right: 1px solid rgba(205, 211, 213, 0.4);
        }
      }
      &__permissions {
        width: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-right: 1px solid #E6E8ED;
        border-left: 1px solid #E6E8ED;
        border-bottom: 1px solid #E6E8ED;
        i {
          color: #BFC0C7;
        }
      }
      &.clickable-active {
        background-color: #E7F6FB;
      }
      &.clickable {
        cursor: pointer;
      }
      &.deleted {
        pointer-events: none;
        .portal-records__table__row__select, .portal-records__table__row__expand, .portal-records__table__row__item, .cell {
          background-color: #fbd8d8;
        }
      }
      &.deleted-fading {
        opacity: 0;
        -webkit-transition: 1.0s;
        -moz-transition: 1.0s;
        -o-transition: 1.0s;
        transition: 1.0s;
        .portal-records__table__row__select, .portal-records__table__row__expand, .portal-records__table__row__item, .cell {
          background-color: #fbd8d8;
          pointer-events: none;
        }
      }
      &__item {
        background-color: #fff;
        padding: 0 .75em;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(205, 211, 213, 0.7);
        border-right: 1px solid rgba(205, 211, 213, 0.4);
        line-height: 1.3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.relational {
          display: flex;
          flex-wrap: wrap;
          overflow-y: scroll;
          align-items: center;
        }
        &.updated {
          background-color: lightgreen;
        }
        &.updated-fading {
          background-color: #fff;
          -webkit-transition: 1.0s;
          -moz-transition: 1.0s;
          -o-transition: 1.0s;
          transition: 1.0s;
        }
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.3em;
          &:hover {
            white-space: normal;
            word-wrap: break-word;
          }
        }
        &.file {
          cursor: pointer;
          color: #0073E2;
          &:hover {
            text-decoration: underline;
            color: #0073E2;
          }
          &.disabled {
            color: black;
            opacity: .5;
            pointer-events: none;
          }
        }
      }
      &:last-child {
        .portal-records__table__row__select {
          border-bottom: none;
        }
        .cell {
          border-bottom: none;
        }
      }
    }
  }
  @media screen and (max-width: $break-medium){
    padding: 1em 2em;
  }
  @media screen and (max-width: $break-small){
    padding: 0 19px 3em;
  }
}

.payment-page-nav {
  background-color: #EDF1F4;
  padding: .5em 19px;
  .portal-brand {
    &__username {
      cursor: default !important;
    }
    &__logo {
      cursor: default !important;
    }
  }
}

.portal-navbar {
  display: flex;
  justify-content: space-between;
  padding: .5em 19px;
  align-items: center;
  flex-direction: row;
  z-index: 99;
  position: fixed;
  width: calc(100% - 215px);
  background-color: #fff;
  border-bottom: 1px solid #E4E9F0;
  &.payment-page {
    width: 100%;
  }
  i {
    color: #778498;
  }
  .dropdown-toggle {
    box-shadow: none !important;
    &:hover {
      opacity: .5;
    }
  }
  .dropdown-toggle::after {
    color: #778498;
  }
  .dropdown-menu {
    left: -15px !important;
  }
  .dropdown-item {
    font-size: 15px;
  }
  &__menu {
    &__dropdown {
      z-index: 999;
    }
  }
  &__name {
    padding: 0 0 0 .5em;
  }
  .portal-brand {
    &__logo {
      display: none;
    }
    &__username {
      display: none;
    }
  }
  @media screen and (max-width: $break-small){
    padding-right: 4px;
    position: fixed;
    width: 100%;
    .portal-brand {
      &__logo {
        display: inline-block;
      }
      &__username {
        display: inline-block;
      }
    }
    &__name {
      display: none;
    }
    &__menu {
      &__dropdown {
        left: -5px !important;
      }
    }
    i {
      padding: 0 5px 0 0;
    }
  }
}

.text-link-button {
  padding: 0;
  border: none;
  font-size: 15px;
  background-color: transparent;
  color: #4760ff;
  &:hover {
    color: #15232b;
    cursor: pointer;
  }
}

/* -- Log in Pages -- */

.login {
  padding: 5em 0;
  background-color: #EDF1F4;
  min-height: 100vh;
  .h2-style {
    font-size: 1.333em;
    font-weight: 800;
    &.padding-bottom {
      padding: 0 0 .5em;
    }
  }
  .p2-style {
    color: #778498;
    padding: .5em 0 0;
  }
  .btn-blue {
    width: 100%;
    margin: 1.5em 0 0;
    padding: .5em .75em;
  }
  &__wrapper {
    max-width: 460px;
    margin: 0 auto;
    padding: 0 19px;
  }
  &__form {
    border-radius: 6px;
    padding: 2em;
    background-color: #fff;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  }
  label {
    color: #1a1f36;
    font-weight: 600;
    font-size: .9em;
    padding: 0 0 .25em;
    margin: 0;
  }
  &__password-reset-header {
    font-size: 1.1em;
    line-height: 1.4em;
  }
  &__footer {
    padding: 1.5em 0 0 0;
    &__already-have-account-text {
      font-size: 15px;
    }
  }
  &__field {
    display: flex;
    flex-direction: column;
    padding: 1em 0 0;
    &:first-child {
      padding-top: 0;
    }
    &--label-row {
      display: flex;
      justify-content: space-between;
    }
  }
  &__logo-section {
    padding: 0 0 2em;
    text-align: center;
    &--image {
      max-width: 13em;
      max-height: 45px;
    }
  }
  &__name-section {
    padding: 0 0 2em;
    text-align: center;
    &--label {
      color: #959fad;
      font-size: 12.5px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .025em;
    }
    &--name {
      text-transform: capitalize;
      font-size: 1.1em;
      font-weight: 700;
      letter-spacing: -.025em;
    }
  }
  &__email-placeholder {
    background-color: #EFF1F5;
    color: #767C89;
    font-size: 1em;
    font-weight: 400;
    border: 1px solid #DEDFE2;
    padding: 8px;
    border-radius: 5px;
  }
  @media screen and (max-width: $break-small){
    padding: 2em 0;
    &__form {
      padding: 1.2em;
    }
    &__logo-section {
      padding: 0 0 1em;
    }
  }
}

.login-successes {
  background-color: #CCE5FF;
  padding: 1em;
  border-radius: 6px;
  margin-top: 1em;
  color: #004085;
  border: 1px solid #B8DAFF;
}

.login-errors {
  background-color: #f8d7da;
  padding: 1em;
  border-radius: 6px;
  margin-top: 1em;
  font-size: 15px;
  line-height: 1.4em;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.ReactModal__Overlay {
  z-index: 100;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,.8) !important;
}

.cell {
  border-right: 1px solid rgba(205,211,213,.4);
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 .75em;
  font-size: .95em;
  background-color: #fff;
  border-bottom: 1px solid rgba(205, 211, 213, 0.7);
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  &.active {
    background-color: #E7F6FB;
  }
  &.relational {
    .file {
      border: none;
    }
  }
  &.clickable {
    cursor: pointer;
  }
  &.clickable-active {
    background-color: #E7F6FB;
  }
  &.overflow {
    overflow: visible;
  }
  &.file {
    cursor: pointer;
    color: #0073E2;
    &:hover {
      text-decoration: underline;
      color: #0073E2;
    }
    &.disabled {
      color: black;
      opacity: .5;
      pointer-events: none;
    }
    &.no-hover {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.updated {
    background-color: lightgreen;
  }
  &.updated-fading {
    background-color: #fff;
    -webkit-transition: 1.0s;
    -moz-transition: 1.0s;
    -o-transition: 1.0s;
    transition: 1.0s;
  }
  .DraftEditor-root {
    height: 2.4em;
  }
  input {
    outline: none !important;
  }
  &.selected {
    padding: 0;
    z-index: 3;
    &.invalid {
      input {
        background-color: #F8D7DA;
      }
    }
    input {
      width: 100%;
      height: 100%;
      background-color: #E7F6FB;
      padding: 6.5px 5px 6.5px 9px;
      border: 2px solid #2D7FF9;
      border-radius: 2px;
      &.disabled {
        opacity: .5;
      }
    }
    .DraftEditor-root {
      padding: 0 .75em;
    }
    .react-datepicker-wrapper, .react-datepicker__input-container {
      width: 100%;
    }
  }
  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.2rem;
    line-height: 1.2rem;
  }
  &__dropdown-menu {
    font-size: 1em !important;
    box-shadow: 0 2px 4px rgba(0,0,0,.25) !important;
    border-radius: 3px !important;
    min-width: 125px !important;
    z-index: 100 !important;
    padding: 0 !important;
    border: 1px solid #CDD3D5;
    i {
      padding: 0 8px 0 0;
    }
    &--table {
      left: 0 !important;
    }
    &--column {
      min-width: 150px !important;
    }
    .dropdown---menu-item---1LjoL {
      padding: 8px 18px !important;
      &:hover {
        background-color: #F4F4F4 !important;
      }
    }
    &--btn {
      border-top: 1px solid #CDD3D5;
    }
    &--delete-btn {
      .dropdown---menu-item---1LjoL {
        border-top: 1px solid #CDD3D5;
        color: #d91e18 !important;
        i {
          color: #d91e18 !important;
        }
      }
    }
  }
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    line-height: 1.3em;
    .public-DraftEditor-content {
      white-space: nowrap !important;
    }
    .fa-files-o {
      margin-right: 5px;
    }
    &__linked-value {
      background-color: #E3E8EF;
      padding: 2px 4px;
      margin: 3px;
      border-radius: 8px;
      font-size: 15px;
      border: none;
      color: #4F576C;
      box-shadow: none;
      cursor: default;
      white-space: nowrap;
      &--read-only {
      }
      &--writable {
        background-color: #D1DCEC;
        font-weight: 400;
        box-shadow: 0px 2px 0px #8793A4;
        cursor: pointer;
        transition: .25s;
        &:hover {
          background-color: #B7C5D7;
        }
      }
    }
    &__file-controls {
      padding: 0 20%;
      width: 100%;
      display: flex;
      justify-content: space-around;
      button.secondary {
        padding: 5px 14px;
      }
      .fa-trash {
        color: #d91e18;
      }
    }
  }
  &__dropdown {
    width: 100%;
  }
  &__dropdown-btn {
    display: flex;
    button {
      width: 100% !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
    &__clear {
      div { font-weight: 600 !important; }
    }
  }
  input.cell__item__file-upload-input {
    display: none;
  }
}

.raw-content-modal {
  padding: 1em 1em .5em;
  &__link-popover {
    position: absolute;
    top: 60px;
    left: 60px;
    z-index: 10;
    padding: 3px;
    border: 1px solid lightgrey;
    background-color: white;
    input {
      display: block;
      flex: 1 0 auto;
      height: 28px;
      background: none white;
      border: 1px solid darkgrey;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 2px 6px;
      font-family: inherit;
      font-size: inherit;
      line-height: 20px;
      font-size: 14px;
      width: 250px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: -10px;
      left: 130px;
      border: 5px solid transparent;
      border-bottom-color: #999;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: -9px;
      left: 130px;
      border: 5px solid transparent;
      border-bottom-color: #fdfdfd;
    }
  }
  &__toolbar {
    display: flex;
    justify-content: center;
    padding: .5em;
    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin: 0 .3em;
      border: 1px solid lightgrey;
      border-radius: 3px;
      cursor: pointer;
      &.disabled {
        opacity: .3;
        pointer-events: none;
      }
      &:hover {
        background-color: #E8E8E8;
      }
      &--active {
        box-shadow: 1px 1px 0px #BFBDBD inset;
        background-color: #E8E8E8;
      }
      &--bold {
        font-weight: 600;
      }
      &--italic {
        font-style: italic;
      }
    }
    &__btn-group {
      display: flex;
      padding: 0 .5em;
    }
  }
  &__editor {
    height: 10em;
    padding: .5em;
    border: 1px solid lightgrey;
    border-radius: 3px;
    overflow: scroll;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5em;
    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 70px;
      &:first-child {
        margin-right: 2em;
      }
    }
  }
}

.pagination-toolbar {
  padding: .5em .75em;
  bottom: 0;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  z-index: 2;
  &__button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__page-btn {
    border: 1px solid hsl(0,0%,80%);
    background-color: #fff;
    border-radius: 4px;
    margin: 0 .25em;
    font-size: .9em;
    cursor: pointer;
    &:hover {
      background-color: #F6F6F6;
    }
    &.disabled {
      background-color: #F6F6F6;
      opacity: .5;
    }
  }
  &__text {
    color: #7E8594;
    padding: 0 0 0 .5em;
  }
  &__current-pages {
    color: #7E8594;
    padding: 0 .5em 0 1em;
  }
  .custom-select {
    &__control {
      min-width: 80px;
    }
  }
  @media screen and (max-width: $break-small){
    flex-direction: column;
    &__button-wrapper:first-child {
      margin: 0 0 1em;
    }
  }
}

.table-filter-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: none;
  outline: none !important;
  border-radius: 5px;
  min-width: 200px;
  width: 50%;
  min-height: 25em;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  box-shadow: 3px 2px 15px rgba(0,0,0,.3);
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 2em;
    justify-content: space-between;
    &.disabled {
      opacity: .7;
      pointer-events: none;
    }
  }
  &__header {
    border-bottom: 1px solid #cdd3d5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1em;
    margin: 0 0 1em;
    &__exit-btn {
      color: #7e8594;
      border: none;
      background-color: transparent;
      &:hover {
        opacity: .5;
      }
    }
    &__label {
      font-size: 1em;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: #5c6a7f;
    }
  }
  &__explainer {
    color: #5c6a7f;
    font-size: 15px;
    padding: 0 0 1em;
  }
  &__expression {
    display: flex;
    align-content: center;
    margin: 0 0 1em;
    &__select-col {
      flex-basis: 30%;
      margin: 0 .5em;
    }
    &__label {
      padding: 0 0 .25em;
      margin: 0;
      font-size: 14.5px;
      font-weight: 600;
      color: #1b1f37;
    }
    &__delete-col {
      flex-basis: 5%;
      padding-top: 32px;
    }
    &__delete-button {
      background-color: transparent;
      border: none;
      color: #7e8594;
      &:hover {
        opacity: .5;
      }
    }
    .invalid {
      .custom-select__control {
        border-color: #e74c3c !important;
      }
    }
  }
  &__add-filter {
    padding-left: 0;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 3px;
    &.disabled {
      background-color: #D7D7D7;
      border: 1px solid #D7D7D7;
      color: #fff;
    }
  }
  &__actions {
    text-align: right;
  }
  @media screen and (max-width: $break-small){
    width: 100%;
    height: 100vh;
    overflow: scroll;
    &__wrapper {
      padding: 1em;
    }
    &__expression {
      flex-direction: column;
      &__select-col {
        width: 100%;
        margin: 0 0 .5em;
      }
    }
  }
}

.table-filter-notification {
  background-color: #fff3cd;
  border-radius: 8px;
  color: #856404;
  margin-top: -1em;
  margin-bottom: 1em;
  display: inline-block;
  border: 1px solid #ffeeba;
  font-size: .9em;
  padding: .5em 1em;
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__actions {
    display: flex;
  }
  &__button {
    cursor: pointer;
    font-weight: 600;
    &:hover {
      opacity: .5;
    }
    i {
      padding: 0 .5em 0 0;
    }
    &:last-child {
      margin-left: 1.5em;
    }
  }
}

.row-expanded-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: none;
  width: 60%;
  max-height: 80%;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  outline: none !important;
  background-color: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  overflow-y: scroll;
  &.deleted {
    pointer-events: none;
  }
  &__deleted-row {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .5;
    display: flex;
    justify-content: center;
    z-index: 100;
    &__message {
      width: 100%;
      background-color: #d8959e;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-height: 5em;
    }
  }
  &__dropdown-menu {
    font-size: 15px !important;
    box-shadow: 0 2px 4px rgba(0,0,0,.25) !important;
    border-radius: 3px !important;
    min-width: 125px !important;
    left: auto !important;
    right: -5px !important;
    z-index: 100 !important;
    padding: 0 !important;
    border: 1px solid #CDD3D5;
    i {
      padding: 0 8px 0 0;
    }
    &--table {
      left: 0 !important;
    }
    &--column {
      min-width: 165px !important;
    }
    .dropdown---menu-item---1LjoL {
      &:hover {
        background-color: #F4F4F4 !important;
      }
    }
    &--btn {
      border-top: 1px solid #CDD3D5;
      &:first-child {
        border-top: none;
      }
      &__remove-sort {
        margin-left: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .fa-times {
          padding: 0;
        }
      }
      &.current-sort {
        div.dropdown---menu-item---1LjoL {
          display: flex;
        }
      }
    }
    &__type-button {
      height: 2em;
      padding-left: 1em;
    }
    &--delete-btn {
      .dropdown---menu-item---1LjoL {
        border-top: 1px solid #CDD3D5;
        color: #d91e18 !important;
        i {
          color: #d91e18 !important;
        }
      }
    }
  }
  &__close-btn {
    border: none;
    background-color: transparent;
    color: #8891A4;
  }
  &__nav {
    padding: 3em 3em 0;
    background-color: #F8FAFC;
    border-bottom: 1px solid #E3E8EF;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--main {
        justify-content: center;
        h5 {
          margin-bottom: 1em;
        }
      }
    }
    &--actions-row {
      display: flex;
      flex-direction: row;
      padding: 1.5em 0 1.5em;
      align-items: center;
      &--leftCol {
        flex-basis: 50%;
      }
      &--rightCol {
        flex-basis: 50%;
      }
      .custom-select {
        min-width: 200px;
        &__menu {
          width: 100% !important;
        }
      }
    }
    &__breadcrumb-wrapper {
      display: flex;
      align-items: center;
      flex-basis: 90%;
      .fa-angle-right {
        color: #8891A4;
      }
    }
    &__breadcrumb {
      margin: 0 .5em;
      color: #8891A4;
      cursor: pointer;
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      &:hover {
        color: #0073e2;
      }
      &:first-child {
        margin-left: 0;
      }
      &.inactive {
        cursor: default;
        color: #0073e2;
        font-weight: 600;
      }
    }
    button[disabled] {
      opacity: .7;
      box-shadow: none;
      &:hover {
        background-color: #fff;
      }
    }
    &__nav-button {
      background-color: #fff;
      color: #4f566b;
      border-radius: 5px;
      border: 1px solid #e3e8ef;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      transition: .25s;
      i {
        color: #8891A4;
      }
      &:hover {
        box-shadow: none;
        background-color: #F1F1F1;
      }
      &:first-child {
        margin: 0 .25em 0 0;
        i {
          padding: 0 .25em 0 0;
        }
      }
      &:last-child {
        margin: 0 0 0 .25em;
        i {
          padding: 0 0 0 .25em;
        }
      }
    }
  }
  &__wrapper {
  }
  &__body {
    padding: 1em 0 3em;
    background-color: #F8FAFC;
  }
  &__loading-wrapper {
    background-color: #F8FAFC;
    text-align: center;
    padding: 2em 1.5em;
    h4 {
      font-size: 18px;
    }
  }
  &__loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 30px;
    border: none;
  }
  .cell__item__linked-value {
    white-space: pre-wrap;
    display: inline-block;
    overflow: visible;
    margin-bottom: 5px;
  }
  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5em 3em;
    &.relational {
      display: block;
    }
    &.invalid {
      input {
        background-color: #F8D7DA;
      }
    }
    &.updated {
      background-color: lightgreen;
    }
    &.updated-fading {
      background-color: #F8FAFC;
      -webkit-transition: 1.0s;
      -moz-transition: 1.0s;
      -o-transition: 1.0s;
      transition: 1.0s;
    }
    &.allows-deletion {
      padding-right: 1em;
    }
    &__column-name {
      padding: 0 0 .25em;
      margin: 0;
      font-size: .9em;
      font-weight: 600;
      color: #62697b;
      .fa-edit {
        cursor: pointer;
        margin-left: .5em;
      }
    }
    &__input {
      width: 100%;
      padding: 5px;
      border: 1px solid #bdc4c9;
      border-radius: 3px;
    }
    &__linked-value-empty {
      width: 100%;
      padding: .25em;
      border: 1px dashed #ADB0BA;
      color: #808A9F;
      font-size: 15px;
      text-align: center;
      display: inline-block;
      border-radius: 4px;
    }
    label.secondary {
      cursor: pointer;
    }
    input[type=text]{
      border: 1px solid #bdc4c9;
      border-radius: 3px;
    }
    &__file-input {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      min-height: 36px;
      padding: 5px;
      border: 1px solid #bdc4c9;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__name {
        .fa-files-o {
          margin-right: .5em;
        }
      }
      &__controls {
        label.secondary, button.secondary {
          color: #7e8594;
          margin: 0 .25em 0 0;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
          &:hover {
            background-color: #F6F6F6;
          }
        }
      }
      input.row-expanded-modal__cell__file-input__upload {
        display: none;
      }
      button.secondary {
        padding: 5px 14px;
        font-size: 15px;
        line-height: 21px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        background-color: #fff;
        margin-bottom: 0;
        color: #7e8594;
        margin: 0 .25em 0 0;
      }
    }
    .row-expanded-modal__cell__dropdown-item > div.dropdown---menu-item---1LjoL {
      white-space: inherit;
    }
    &__raw-content-input {
      width: 100%;
      padding: 5px;
      background-color: #fff;
      border: 1px solid #bdc4c9;
      border-radius: 3px;
      max-height: 10em;
      overflow-y: scroll;
      &.editable {
        cursor: pointer;
      }
    }
    &__dropdown {
      .buttons---btn-group---1jaDo {
        width: 100%;
      }
    }
    button.row-expanded-modal__cell__dropdown-btn {
      min-width: 10em;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff !important;
      color: #3c4257;
      border-color: #e3e8ef !important;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      transition: .25s;
      &.large {
        min-width: 20em;
      }
    }
    &__relational-row {
      display: flex;
      border: 1px solid #E0E3E5;
      margin: 0 0 .5em;
      border-radius: 3px;
      background-color: #F8FAFC;
      &:last-child {
        margin-bottom: 0;
      }
      &.disabled {
        pointer-events: none;
        background-color: #fff;
        input {
          display: none;
        }
        .databases__relation-modal__row-card__row-info__group {
          opacity: .5;
        }
      }
      &__linked-row-info {
        &__description {
          font-size: .9em;
          color: #6D7483;
          &--warning {
            color: #721c24;
            padding: .5em;
            background-color: #f8d7da;
            border: 1px solid #f5c6cb;
            border-radius: 5px;
          }
        }
        &__values {
          padding: .25em 0;
          &__value {
            background-color: #E9EEF9;
            color: #4B556C;
            display: inline-block;
            line-height: 1.3em;
            padding: 4px 6px;
            margin: 0 .25em .25em 0;
            border-radius: 8px;
            font-size: 15px;
            cursor: default;
            &:last-child {
              margin-right: 0;
            }
            &--own-row {
              font-weight: 600;
            }
          }
        }
      }
      &__select-row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 10%;
        min-width: 10%;
        border-right: 1px solid #E0E3E5;
        min-height: 100px;
        .fa-external-link {
          cursor: pointer;
          padding: .5em;
          font-weight: 900;
        }
      }
      &__delete-row {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        border-right: 1px solid #E0E3E5;
        min-height: 100px;
        &:hover {
          background-color: #f0f4f7;
          cursor: pointer;
        }
        .fa-trash-o {
          padding: .5em;
          color: #D91E18;
        }
      }
      &__row-info {
        display: flex;
        flex-direction: column;
        flex-basis: 90%;
        max-width: 90%;
        padding: 1em;
        max-height: 12em;
        &__column-value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          &--primary {
            font-size: 1em;
            font-weight: 600;
            padding-bottom: 0.25em;
            min-height: 26px;
            max-width: 10em;
          }
          .small_spinner {
            display: inline-block;
          }
        }
      }
    }
  }
  .relation-modal__row-card__select-row {
    background-color: #F8FAFC;
    .fa-external-link {
      cursor: pointer;
    }
  }
  @media screen and (max-width: $break-small){
    width: 100%;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.tile-view {
  border: 1px solid #E4E9F0;
  box-shadow: 0 2px 3px rgba(130, 130, 130, .5);
  border-radius: 8px;
  background-color: #fff;
  margin: 0 0 2em;
  &__header {
    padding: 1em;
  }
  &__title {
    font-weight: 700;
    font-size: 1.333em;
    padding: 0;
    margin: 0;
  }
  &__action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em 1em 1em;
    &__col {
      padding: 0 1.25em 0 0;
      &.flex {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    &__toolbar-col {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1.25em 0 0;
    }
    .actions-select {
      min-width: 200px;
    }
    .btn-white {
      i {
        padding: 0 .5em 0 0;
      }
    }
  }
  .cell {
    border-right: none;
    border-bottom: none;
    flex-basis: 22%;
    min-width: auto !important;
    max-width: none !important;
    text-overflow: ellipsis;
    overflow: hidden;
    &__item__tile-image {
      max-height: 45px;
      max-width: 45px;
    }
  }
  .cell-wrapper {

  }
  .portal-detail-view__file-input {
    margin-top: 0;
  }
  .portal-records__table__row__expand-row-col-header-placeholder {
    display: none;
  }
  .portal-records__table {
    overflow: hidden;
    &__column-names {
      width: auto;
      border-bottom: 1px solid rgba(205, 211, 213, 0.7);
      &__item-wrapper {
        flex-basis: 22%;
        &--empty {
          display: none;
        }
        &--portrait {
          .portal-records__table__column-names__item {
            display: none;
          }
        }
      }
      &__item {
        border-right: none;
        border-bottom: none;
        min-width: auto !important;
        max-width: none !important;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    &__row-wrapper {
      width: auto;
    }
    &__row {
      display: flex;
      min-height: 55px;
      align-items: center;
      border-bottom: 1px solid rgba(205, 211, 213, 0.7);
      &:last-of-type {
        border-bottom: none;
      }
      &__select {
        border-right: none;
        border-bottom: none;

        &--all {
          border-right: none;
        }
      }
    }
  }
  @media screen and (max-width: $break-small){
    .tiles-column-1 {
      flex-basis: auto !important;
      max-width: 100% !important;
    }
    .tiles-column-2, .tiles-column-3 {
      display: none;
    }
    .cell-wrapper:nth-child(2){
      flex-basis: auto!important;
      max-width: none!important;
      min-width: 62.78px;
    }
    .portal-records__table {
      &__row {

      }
    }
    .cell {
      &__item__tile-image {
        max-width: 40px;
        max-height: 40px;
      }
    }
    .portal-records__table {
      &__column-names__item-wrapper--portrait {
        width: 62.78px;
        flex-basis: auto!important;
        max-width: none!important;
      }
    }
    &__action-row {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1em 0;
      &__toolbar-col {
        padding: 0 0 1em;
      }
      &__col {
        padding: 0 0 1em;
        &.flex {
          flex-direction: column;
          align-items: flex-start;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.classic-table-view {
  border: 1px solid #E4E9F0;
  box-shadow: 0 2px 3px rgba(130, 130, 130, .5);
  border-radius: 8px;
  background-color: #fff;
  margin: 0 0 2em;
  &__header {
    padding: 1em;
  }
  &__title {
    font-weight: 700;
    font-size: 1.333em;
    padding: 0;
    margin: 0;
  }
  &__action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em 1em 1em;
    &__col {
      padding: 0 1.25em 0 0;
      &.flex {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    &__toolbar-col {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1.25em 0 0;
    }
    .actions-select {
      min-width: 200px;
    }
    .btn-white {
      i {
        padding: 0 .5em 0 0;
      }
    }
  }
  @media screen and (max-width: $break-small){
    &__action-row {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1em 0;
      &__toolbar-col {
        padding: 0 0 1em;
      }
      &__col {
        padding: 0 0 1em;
        &.flex {
          flex-direction: column;
          align-items: flex-start;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.removed-section {
  padding: 3em 5em;
  color: #778498;
  text-align: center;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 61px;
    padding: 0 0 .25em 0;
    color: #1a1f36;
    justify-content: center;
    i {
      font-size: 1.1em;
    }
    &__title {
      padding: 0 0 0 .5em;
      margin: 0;
      font-weight: 700;
      font-size: 1.1em;
    }
  }
}

.portal-detail-view {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #E4E9F0;
  box-shadow: 0 2px 3px rgba(130, 130, 130, .5);
  border-radius: 8px;
  &.single-record {
    margin-bottom: 2em;
  }
  &.deleted {
    .portal-detail-view__sections, .portal-detail-view__nav {
      pointer-events: none;
      opacity: .5;
    }
  }
  &__back {
    margin-top: 115.5px;
    padding: 0 0 1em;
    &__button {
      color: #4760ff;
      font-weight: 600;
      background-color: transparent;
      border: none;
      outline: none !important;
      i {
        padding: 0 .5em 0 0;
      }
      &:hover {
        color: #1D2FA0;
      }
    }
  }
  &__nav {
    padding: 1em;
    border-bottom: 1px solid #E4E9F0;
    &--empty {
      padding: 0;
    }
    &__back {
      border: none;
      background-color: #F7FAFC;
      i.fa-chevron-left {
        font-size: 14px;
        margin-right: .5em;
      }
    }
    &__actions {
      width: 100%;
      display: flex;
      align-items: flex-start;
      .actions-select {
        min-width: 200px;
        margin-top: .75em;
        &__menu {
          width: 100% !important;
        }
      }
    }
    &__nav-button {
      background-color: #fff;
      color: #4f566b;
      border-radius: 5px;
      border: 1px solid #e3e8ef;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      transition: .25s;
      i {
        color: #8891A4;
      }
      &:hover {
        box-shadow: none;
        background-color: #F1F1F1;
      }
      &:first-child {
        margin: 0 .25em 0 0;
        i {
          padding: 0 .25em 0 0;
        }
      }
      &:last-child {
        margin: 0 0 0 .25em;
        i {
          padding: 0 0 0 .25em;
        }
      }
      &:disabled {
        box-shadow: none;
        opacity: .6;
        background-color: #fff !important;
      }
    }
    &__breadcrumb-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      .fa-angle-right {
        color: #5c6a7f;
      }
    }
    &__header-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__leftCol {
        flex-basis: 70%;
      }
      &__rightCol {
        flex-basis: 25%;
        text-align: right;
      }
    }
    &__breadcrumb {
      margin: 0 .5em;
      color: #4760ff;
      cursor: pointer;
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      font-weight: 600;
      &:hover {
        color: #00254C;
      }
      &:first-child {
        margin-left: 0;
      }
      &.inactive {
        cursor: default;
        color: #5c6a7f;
        font-weight: 400;
      }
    }
  }
  &__deleted-row {
    position: fixed;
    top: 50%;
    left: auto;
    height: 100%;
    width: 80%;
    opacity: .5;
    display: flex;
    justify-content: center;
    z-index: 100;
    &__message {
      width: 100%;
      background-color: #d8959e;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-height: 5em;
    }
  }
  &__dropdown-btn {
    min-width: 10em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff !important;
    color: #3c4257;
    border-color: #e3e8ef !important;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    transition: .25s;
    &.large {
      min-width: 20em;
    }
  }
  &__dropdown-menu {
    font-size: 15px !important;
    box-shadow: 0 2px 4px rgba(0,0,0,.25) !important;
    border-radius: 3px !important;
    min-width: 125px !important;
    left: auto !important;
    right: -5px !important;
    z-index: 100 !important;
    padding: 0 !important;
    border: 1px solid #CDD3D5;
    i {
      padding: 0 8px 0 0;
    }
    &--table {
      left: 0 !important;
    }
    &--column {
      min-width: 165px !important;
    }
    .dropdown---menu-item---1LjoL {
      padding: .5em .75em !important;
      &:hover {
        background-color: #F4F4F4 !important;
      }
    }
    &--btn {
      border-top: 1px solid #CDD3D5;
      &:first-child {
        border-top: none;
      }
      &__remove-sort {
        margin-left: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .fa-times {
          padding: 0;
        }
      }
      &.current-sort {
        div.dropdown---menu-item---1LjoL {
          display: flex;
        }
      }
    }
    &__type-button {
      height: 2em;
      padding-left: 1em;
    }
    &--delete-btn {
      .dropdown---menu-item---1LjoL {
        border-top: 1px solid #CDD3D5;
        color: #d91e18 !important;
        i {
          color: #d91e18 !important;
        }
      }
    }
  }
  &__raw-content-input {
    width: 100%;
    padding: 5px;
    background-color: #fff;
    &.editable {
      cursor: pointer;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, .075);
      border-radius: 5px;
      padding: 6px 8px;
      border: 1px solid #ced4da;
    }
  }
  &__file-input {
    margin-top: 5px;
    display: flex;
    min-height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--image {
      flex-direction: column;
      align-items: flex-start;
      img {
        max-height: 200px;
        max-width: 200px;
        border: 1px solid #E4E9F0;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    &__name {
      .fa-files-o {
        margin-right: .5em;
      }
    }
    &__controls {
      padding-left: 1em;
      &:first-child {
        padding-left: 0;
      }
      &--image {
        padding-left: 0;
      }
      label.secondary, button.secondary {
        color: #7e8594;
        margin: 0 .25em 0 0;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
        &:hover {
          background-color: #F6F6F6;
        }
      }
      &__row {
        padding: 5px 0;
        i.fa-files-o {
          margin-right: 5px;
        }
      }
    }
    input.portal-detail-view__file-input__upload {
      display: none;
    }
    .btn-white {
      margin: 0 1em 0 0;
    }
  }
  &__sections {
    display: flex;
    flex-direction: column;
    padding: 1em;
    &__section {
      margin-top: 1em;
      &:first-child {
        margin-top: 0;
      }
      &__input {
        &:disabled {
          background-color: #fff;
          border: none;
          color: #212529;
          box-shadow: none;
          padding: 0;
        }
      }
      &__header {
        padding: 0 0 .75em;
        font-weight: 700;
        font-size: 1.333em;
      }
      &__body {
        display: flex;
        flex-wrap: wrap;
      }
      &__column-name {
        color: #252C47;
        font-size: .9em;
        font-weight: 700;
      }
    }
    &__relational-value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #f4f5f8;
      border-radius: 4px;
      padding: .25em .5em;
      margin: 0 0 .5em;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
      &__leftCol {
        flex-basis: 75%;
      }
      &__rightCol {
        flex-basis: 22%;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__display-value {
        color: #494e5f;
      }
      &__expand-btn {
        color: #0073e2;
        cursor: pointer;
        &:hover {
          color: #00254C;
        }
      }
      &__delete-btn {
        margin: 0 .5em 0 1em;
        color: #4f566b;
        cursor: pointer;
        &:hover {
          opacity: .5;
        }
      }
    }
    &__permission {
      display: flex;
      flex-direction: column;
      padding: 0 0 1.5em;
      flex-grow: 1;
      border-radius: 5px;
      align-items: flex-start;
      &--single-column {
        width: 100%;
        input {
          width: 100%;
        }
        .portal-detail-view__dropdown {
          width: 25%;
          .dropdown---dropdown---1yvIZ {
            width: 100%;
          }
        }
      }
      &--two-column {
        width: 50%;
        max-width: 50%;
        &:nth-child(odd) {
          padding-right: 1em;
        }
        &:nth-child(even) {
          padding-left: 1em;
        }
        input {
          width: 100%;
        }
        .portal-detail-view__dropdown {
          width: 100%;
          .dropdown---dropdown---1yvIZ {
            width: 100%;
          }
        }
      }
      &.invalid {
        input {
          background-color: #F8D7DA;
        }
      }
      /*&.relational {
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        align-items: center;
      }*/
      &.updated {
        background-color: lightgreen;
      }
      &.updated-fading {
        background-color: #F7FAFC;
        -webkit-transition: 1.0s;
        -moz-transition: 1.0s;
        -o-transition: 1.0s;
        transition: 1.0s;
      }
    }
  }
  @media screen and (max-width: $break-small){
    &__nav {
      &__header-row {
        flex-direction: column;
        &__leftCol {
          flex-basis: 100%;
          padding: 0 0 .5em;
        }
        &__rightCol {
          flex-basis: 100%;
        }
      }
      &__actions {
        justify-content: center;
      }
    }
  }
}

.material-icons {
  color: #1e232a;
  width: 24px;
  margin-right: 5px;
}